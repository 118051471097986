import { faCaretDown, faCircleCaretDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Flex,
  Text,
  List,
  ListItem,
  Menu,
  Stack,
  Title,
  UnstyledButton,
  Button,
  Center,
  Loader,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
// @ts-ignore
import * as echarts from 'echarts/dist/echarts.esm';
import { useNavigate } from 'react-router-dom';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { AnalyticsService } from '@/services/AnalyticsService';
import { NoResultsListTable } from '@/components/Menus/NoResultsListTable/NoResultsListTable';
import { format, subDays, subHours, subMonths, subYears } from 'date-fns';
import { numberToCommas } from '@/utils/StringUtility';
import { endOfDay, startOfDay } from 'date-fns';

export function DashboardAnalytics() {
  const navigate = useNavigate();
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [label, setLabel] = useState('All time');
  const [value, setValue] = useState({
    fromDate: startOfDay(new Date(defaultWorkspace?.created_at || '')).toISOString(),
    toDate: endOfDay(new Date()).toISOString(),
  });

  function formatDateRange(value: string) {
    let startDate;
    const endDate = new Date(); // current date and time

    switch (value) {
      case 'last_24_hours':
        startDate = subHours(endDate, 24);
        break;
      case 'last_7_days':
        startDate = subDays(endDate, 7);
        break;
      case 'last_30_days':
        startDate = subMonths(endDate, 1);
        break;
      case 'last_90_days':
        startDate = subMonths(endDate, 3);
        break;
      case 'last_6_months':
        startDate = subMonths(endDate, 6);
        break;
      case 'last_1_year':
        startDate = subYears(endDate, 1);
        break;
      default:
        return ''; // 'All time' could be represented by the Unix Epoch (1970-01-01)
    }
    // return {
    //   fromDate: format(startDate, "yyyy-MM-dd'T'HH:mm:ss"),
    //   toDate: format(endDate, "yyyy-MM-dd'T'HH:mm:ss"),
    // };

    return `${format(startDate, "yyyy-MM-dd'T'HH:mm:ss")}_${format(
      endDate,
      "yyyy-MM-dd'T'HH:mm:ss"
    )}`;
  }
  const [data, setData] = useState<any>();
  const [option, setOption] = useState({
    title: {
      //   text: 'Nightingale Chart',
      //   subtext: 'Fake Data',
      left: 'center',
    },
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b} : {c} ',
    },
    color: ['#003aa9', '#F9BC4D', '#3AA956'],
    legend: {
      left: 'center',
      top: 'bottom',
      data: ['rose1', 'rose2', 'rose3', 'rose4', 'rose5', 'rose6', 'rose7', 'rose8'],
    },
    toolbox: {
      show: false,
      feature: {
        mark: { show: true },
        dataView: { show: true, readOnly: false },
        restore: { show: true },
        saveAsImage: { show: false },
      },
    },
    series: [
      {
        name: 'Short Links',
        type: 'pie',
        radius: [50, 100],
        center: ['45%', '50%'],
        roseType: 'radius',
        itemStyle: {
          borderRadius: 5,
        },
        label: {
          show: false,
        },
        emphasis: {
          label: {
            show: true,
          },
        },
        data: [
          { value: 0, name: 'Total Clicks' },
          { value: 0, name: 'Unique Clicks' },
          { value: 0, name: 'QR Scans' },
        ],
      },
    ],
  });

  const [loading, setLoading] = useState(true);
  const fetchAnalytics = async () => {
    setLoading(true);
    await new AnalyticsService()
      .overview(defaultWorkspace?._id || '', null, null, value.fromDate || '', value.toDate || '')
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          setData(res.data.data.current);
          setOption({
            ...option,
            series: [
              {
                ...option.series[0],
                data: [
                  { value: res.data.data?.current?.clicks, name: 'Total Clicks' },
                  { value: res.data.data?.current?.unique_clicks, name: 'Unique Clicks' },
                  { value: res.data.data?.current?.qr_scans, name: 'QR Scans' },
                ],
              },
            ],
          });
        } else {
        }
      })
      .catch((err) => { });
    setLoading(false);
  };

  useEffect(() => {
    setValue({
      fromDate: startOfDay(new Date(defaultWorkspace?.created_at || '')).toISOString(),
      toDate: endOfDay(new Date()).toISOString(),
    })
  }, [defaultWorkspace?._id]);

  useEffect(() => {
    fetchAnalytics();
  }, [value]);
  return (
    <>
      <Box
        h={450}
        p={16}
        style={{
          border: '2px dashed var(--mantine-color-gray-4)',
          borderSpacing: '8px',
          borderRadius: '0.25rem',
        }}
      >
        <Flex align={'center'}>
          <Title
            order={5}
            c="primary"
            style={{
              flexGrow: 1,
            }}
          >
            Short Links Analytics
          </Title>{' '}
          <Menu>
            <Menu.Target>
              <UnstyledButton
                style={{
                  fontSize: '0.875rem',
                  textDecoration: 'underline',
                }}
              >
                <Flex align={'center'}>
                  {label}{' '}
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{
                      marginLeft: 8,
                    }}
                  />
                </Flex>
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown ml={-24}>
              {[
                {
                  label: 'All time',
                  value: '',
                },
                {
                  label: 'Last 24 hours',
                  value: formatDateRange('last_24_hours'),
                },
                {
                  label: 'Last 7 days',
                  value: formatDateRange('last_7_days'),
                },
                {
                  label: 'Last 30 days',
                  value: formatDateRange('last_30_days'),
                },
                {
                  label: 'Last 90 days',
                  value: formatDateRange('last_90_days'),
                },
                {
                  label: 'Last 6 months',
                  value: formatDateRange('last_6_months'),
                },
                {
                  label: 'Last 1 year',
                  value: formatDateRange('last_1_year'),
                },
              ].map((item) => (
                <Menu.Item
                  key={item.label + '-dashboard'}
                  onClick={() => {
                    setLabel(item.label);
                    if (item.value === '') {
                      setValue({
                        fromDate: startOfDay(new Date(defaultWorkspace?.created_at || '')).toISOString(),
                        toDate: endOfDay(new Date()).toISOString(),
                      });
                    } else {
                      setValue({
                        fromDate: item.value.split('_')[0],
                        toDate: item.value.split('_')[1],
                      });
                    }
                  }}
                >
                  {item.label}
                </Menu.Item>
              ))}
            </Menu.Dropdown>
          </Menu>
        </Flex>

        {loading ? (
          <Flex justify={'center'} mt={96}>
            <Loader size={'sm'} />
          </Flex>
        ) : (
          <>
            {parseInt(data?.clicks) === 0 &&
              parseInt(data?.unique_clicks) === 0 &&
              parseInt(data?.qr_scans) === 0 ? (
              <>
                <Box pt={64}>
                  <NoResultsListTable
                    message={'There are no analytics available for selected period.'}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <ReactEChartsCore
                    style={{ height: '280px' }}
                    echarts={echarts}
                    option={option}
                    notMerge
                  />
                </Box>
                <Flex justify={'center'}>
                  <List listStyleType="none" >
                    <List.Item>
                      <Flex align={'center'}>
                        <Flex
                          align={'center'}
                          w={200}
                          style={{
                            flexGrow: 1,
                          }}
                        >
                          <Box bg={'#003aa9'} w={12} h={12} mr={8}>
                            <></>
                          </Box>
                          <Text size="sm">Total Clicks</Text>
                        </Flex>

                        <Flex align={'end'}>
                          <Text ta="right">
                            {numberToCommas(option.series[0].data[0].value || 0)}
                          </Text>
                        </Flex>
                      </Flex>
                    </List.Item>
                    <List.Item>
                      <Flex align={'center'}>
                        <Flex
                          align={'center'}
                          w={200}
                          style={{
                            flexGrow: 1,
                          }}
                        >
                          <Box bg={'#F9BC4D'} w={12} h={12} mr={8}>
                            <></>
                          </Box>
                          <Text size="sm">Unique Clicks</Text>
                        </Flex>

                        <Flex align={'end'}>
                          <Text ta="right" >
                            {numberToCommas(option.series[0].data[1].value || 0)}
                          </Text>
                        </Flex>
                      </Flex>
                    </List.Item>
                    <List.Item>
                      <Flex align={'center'}>
                        <Flex
                          align={'center'}
                          w={200}
                          style={{
                            flexGrow: 1,
                          }}
                        >
                          <Box bg={'#3AA956'} w={12} h={12} mr={8}>
                            <></>
                          </Box>
                          <Text size="sm">QR Scans</Text>
                        </Flex>

                        <Flex align={'end'}>
                          <Text ta="right">
                            {numberToCommas(option.series[0].data[2].value || 0)}
                          </Text>
                        </Flex>
                      </Flex>
                    </List.Item>
                  </List>
                </Flex>
                <Center mt="sm">
                  <Button
                    variant="subtle"
                    size="sm"
                    c="gray"
                    onClick={() => {
                      navigate(`/analytics/${defaultWorkspace?._id}`);
                    }}
                  >
                    View detailed analytics
                  </Button>
                </Center>
              </>
            )}
          </>
        )}
      </Box>
    </>
  );
}
