import { useNavigate } from 'react-router-dom';
import { AuthCarousel } from '@/components/Auth/AuthCarousel';
import {
  Anchor,
  Box,
  Button,
  Flex,
  Grid,
  Image,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import ReplugLogo from '../../assets/images/logos/replug-logo.svg';
import classes from './auth.module.css';
import axios from 'axios';
import { API_URL } from '@/constants/Constants';
import { setAuthTokenLocalStorage } from '@/utils/Storage';
import { useUserStore } from '@/stores/useUserStore';
import { notifications } from '@mantine/notifications';
import { useState } from 'react';
import { useUsermaven } from "@usermaven/react";

export function RegisterPage() {
  const [setUser] = useUserStore((state) => [state.setUser]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const {track} = useUsermaven()
  const form = useForm({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      business_name: '',
    },

    validate: {
      first_name: (value) => (value.trim().length > 0 ? null : 'First name is required'),
      last_name: (value) => (value.trim().length > 0 ? null : 'Last name is required'),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.trim().length > 0 ? null : 'Password is required'),
      business_name: (value) => (value.trim().length > 0 ? null : 'Business name is required'),
    },
  });

  const handleRegisterAccount = async (formValues: any) => {
    formValues.recaptcha_token = '123321';
    setLoading(true);
    await axios
      .post(`${API_URL}/auth/register`, formValues)
      .then((res) => {
        if (res.data.status === 'success') {
          setAuthTokenLocalStorage(res.data.authorisation.token);
          setUser(res.data.user);
          navigate('/verification');
          track('signed_up', {})
          // TODO: add user data to zustand
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
          return;
        }
        notifications.show({
          color: 'red',
          message: 'Something went wrong',
        });
      });
    setLoading(false);
  };

  return (
    <Paper
      style={{
        backgroundColor: '#f2f6f7',
        overflow: 'hidden', // Prevent scrolling
      }}
    >
      <Grid>
        <Grid.Col
          span={3.5}
          style={{
            height: '100%',
          }}
        >
          <Flex mt={16} mx={16} direction="column" className={classes.authContainer}>
            <Box className={classes.authLogoContainer}>
              <Image w={122} h={36} src={ReplugLogo} />
            </Box>
            <Stack h="100%" justify="space-between">
              <Box p={16} px={32}>
                <Title order={4} ta="center" pt={20} pb={40}>
                  {' '}
                  Create Account{' '}
                </Title>
                <form onSubmit={form.onSubmit(handleRegisterAccount)}>
                  <Grid mb={16}>
                    <Grid.Col span={6}>
                      <TextInput
                        label="First Name"
                        placeholder="e.g John"
                        {...form.getInputProps('first_name')}
                      />
                    </Grid.Col>
                    <Grid.Col span={6}>
                      <TextInput
                        label="Last Name"
                        placeholder="e.g Carl"
                        {...form.getInputProps('last_name')}
                      />
                    </Grid.Col>
                  </Grid>
                  <TextInput
                    mb={16}
                    label="Email"
                    placeholder="e.g john.carl@email.com"
                    {...form.getInputProps('email')}
                  />
                  <PasswordInput
                    mb={16}
                    label="Password"
                    placeholder="e.g ********"
                    {...form.getInputProps('password')}
                  />
                  <TextInput
                    mb={16}
                    label="Business name"
                    placeholder="e.g Replug"
                    {...form.getInputProps('business_name')}
                  />
                  <Box
                    mt={40}
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button disabled={loading} loading={loading} type="submit" w={140}>
                      Create
                    </Button>
                  </Box>
                  <Flex p={16} pt={30} direction="column" align="center">
                    <Text pb={8} size="sm">
                      Already have account{' '}
                      <Anchor onClick={() => navigate('/login')} fw="bold">
                        Sign in
                      </Anchor>
                      .
                    </Text>
                  </Flex>
                </form>
              </Box>
              <Flex p={16} direction="column" align="center">
                <Text pb={16} size="sm" ta="center" c="dimmed">
                  By signing up, You agree to our{' '}
                  <Anchor onClick={() => window.open('https://replug.io/terms', '_blank')}>
                    terms of service
                  </Anchor>{' '}
                  and{' '}
                  <Anchor onClick={() => window.open('https://replug.io/privacy', '_blank')}>
                    privacy policy
                  </Anchor>
                  .
                </Text>
              </Flex>
            </Stack>
          </Flex>
        </Grid.Col>
        <Grid.Col span={8.5}>
          <AuthCarousel />
        </Grid.Col>
      </Grid>
    </Paper>
  );
}
