import { BioLinkBlockService } from '@/services/BioLinkBlockService';
import { PreviewService } from '@/services/LinkPreviewService';
import { UtilsService } from '@/services/UtilsService';
import { useBioCardBlockStore } from '@/stores/Bio/useBioCardBlockStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { faArrowsRotate, faImage, faTimes, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Button,
  Center,
  Divider,
  Fieldset,
  Flex,
  Grid,
  Group,
  Image,
  Loader,
  Text,
  TextInput,
  Textarea,
  rem,
} from '@mantine/core';
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { useDebouncedValue } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import { DEFAULT_CARD_BLOCK_VALUES } from '@replug/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BioStyle } from '../Themes/BioStyle';
import { useBioLinkSetupStore } from '@/stores/Bio/useBioLinkSetupStore';
import { ensureHttps } from '@/utils/LinkUtility';

export const BioCardSection = ({ reload }: { reload: () => void }) => {
  const { id } = useParams();
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const [previewLoader, setPreviewLoader] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);
  const [bioCardBlock, setBioCardBlock] = useBioCardBlockStore((state) => [
    state.bioCardBlock,
    state.setBioCardBlock,
  ]);

  const [bioLink] = useBioLinkSetupStore((state) => [state.bioLink]);

  const [debounced] = useDebouncedValue(bioCardBlock.url, 700);

  const fetchLinkPreview = async () => {
    setPreviewLoader(true);

    await new PreviewService()
      .link(debounced)
      .then((response) => {
        if (response.data.status && response.data.data) {
          console.log('response.data', response.data);
          setBioCardBlock({
            ...bioCardBlock,
            ...response.data.data,
          });
        }
      })
      .catch(() => {
        // setBioCardBlock({
        //   ...bioCardBlock,
        //   title: '',
        //   description: '',
        //   image: '',
        // });
      });
    setPreviewLoader(false);
  };

  const handleFileDrop = async (files: any) => {
    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('input_file', files[0]);
      setLoadingImage(true);
      await new UtilsService()
        .uploadImage(formData)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            setBioCardBlock({
              ...bioCardBlock,
              image: res.data.url,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });

      setLoadingImage(false);
      // You can now use formData to send the file data to your server or perform any other actions.
    }
  };

  // Handle add block

  const handleAddBlock = () => {
    if (!bioCardBlock.url) {
      notifications.show({
        color: 'red',
        message: `Please add a URL for the card block`,
      });
      return;
    }

    if (!bioCardBlock.title) {
      notifications.show({
        color: 'red',
        message: `Please add a title for the card block`,
      });
      return;
    }

    if (!bioCardBlock.description) {
      notifications.show({
        color: 'red',
        message: `Please add a description for the card block`,
      });
      return;
    }

    if (!bioCardBlock.image) {
      notifications.show({
        color: 'red',
        message: `Please add an image for the card block`,
      });
      return;
    }

    let payload = {
      ...bioCardBlock,
      bio_link_id: id,
      workspace_id: defaultWorkspace?._id,
    };

    if (bioCardBlock._id) {
      new BioLinkBlockService()
        .update(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioCardBlock(DEFAULT_CARD_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      payload.order = bioLink?.blocks.length + 1;

      if (!bioLink?._id) {
        notifications.show({
          color: 'red',
          message: 'Please first publish your bio link.',
        });
        return;
      }
      new BioLinkBlockService()
        .create(defaultWorkspace?._id || '', payload)
        .then((res) => {
          if (res.data.data) {
            notifications.show({
              color: 'green',
              message: res.data.message,
            });

            setBioCardBlock(DEFAULT_CARD_BLOCK_VALUES);
            reload();
          }
        })
        .catch((err) => {
          if ((err.response.status === 400 ||  err.response.status ===  422) && err?.response?.data?.message) {
            notifications.show({
              color: 'red',
              message: err.response.data.message,
            });
          }
        });
    }
  };

  useEffect(() => {
    if (debounced?.length > 0 && debounced?.startsWith('http') && bioCardBlock?.title === '') {
      fetchLinkPreview();
    }
  }, [debounced]);

  return (
    <>
      <Text ta="center" px={'md'} pb={'md'} fz={14}>
        Add a card to your bio-link that allows you to showcase your content in different styles and
        animations.
      </Text>
      <Divider color="gray.2" />
      <Box p="md">
        <Flex mb={'md'} direction={'column'}>
          <Flex
            align={'flex-end'}
            justify={'end'}
            style={{
              flexGrow: 1,
            }}
          >
            {/* <Switch size="xs" label="Show title" /> */}
          </Flex>
          <Flex>
            <TextInput
              description="Your link title, image and description will be automatically fetched from the URL. You can edit them later."
              w={'100%'}
              label="URL"
              placeholder="Enter your blog post, product url etc."
              value={bioCardBlock.url}
              disabled={previewLoader}
              rightSection={previewLoader && <Loader size="xs" />}
              onChange={(e) => {
                const value = e.currentTarget.value;
                const finalValue = value ? ensureHttps(value) : value;
                setBioCardBlock({
                  ...bioCardBlock,
                  url: finalValue,
                });
              }}
            />
            <Flex direction={'column'} justify={'end'}>
              <ActionIcon
                disabled={previewLoader}
                loading={previewLoader}
                onClick={fetchLinkPreview}
                variant="outline"
                mb={5}
                ml={'sm'}
              >
                <FontAwesomeIcon icon={faArrowsRotate} size="sm" />
              </ActionIcon>
            </Flex>
          </Flex>
        </Flex>
        <Fieldset mb="md">
          <TextInput
            my={'md'}
            label="Title"
            placeholder="Add a title..."
            value={bioCardBlock.title}
            onChange={(e) => {
              setBioCardBlock({
                ...bioCardBlock,
                title: e.currentTarget.value,
              });
            }}
          />
          <Textarea
            label="Description"
            placeholder="Add a description"
            value={bioCardBlock.description}
            onChange={(e) => {
              setBioCardBlock({
                ...bioCardBlock,
                description: e.currentTarget.value,
              });
            }}
          />

          {bioCardBlock.image?.startsWith('http') && (
            <Box
              style={{
                position: 'relative',
              }}
            >
              <Image
                h={350}
                mt={'md'}
                fit="contain"
                style={{
                  borderRadius: rem(4),
                  // border: `1px solid var(--mantine-color-gray-3)`,
                }}
                src={bioCardBlock.image || ''}
                alt={bioCardBlock.title}
              ></Image>
            </Box>
          )}
          {bioCardBlock.image?.startsWith('http') ? (
            <>
              <Center>
                <Button
                  variant="outline"
                  color="red"
                  mt={'md'}
                  onClick={() => {
                    setBioCardBlock({
                      ...bioCardBlock,
                      image: '',
                    });
                  }}
                >
                  Remove Image
                </Button>
              </Center>
            </>
          ) : (
            <>
              <Dropzone
                mt={12}
                onDrop={(files) => handleFileDrop(files)}
                onReject={(files) => console.log('rejected files', files)}
                maxSize={5 * 1024 ** 2}
                accept={IMAGE_MIME_TYPE}
              >
                <Group justify="center" gap="xl" mih={100} style={{ pointerEvents: 'none' }}>
                  <Dropzone.Accept>
                    <FontAwesomeIcon
                      color="gray.7"
                      size="sm"
                      icon={faUpload}
                      style={{
                        width: rem(32),
                        height: rem(32),
                        color: 'var(--mantine-color-blue-6)',
                      }}
                    />
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <FontAwesomeIcon
                      color="gray.7"
                      size="sm"
                      icon={faTimes}
                      style={{
                        width: rem(32),
                        height: rem(32),
                        color: 'var(--mantine-color-red-6)',
                      }}
                    />
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <FontAwesomeIcon
                      color="gray.7"
                      size="sm"
                      icon={faImage}
                      style={{
                        width: rem(32),
                        height: rem(32),
                        color: 'var(--mantine-color-gray-4)',
                      }}
                    />
                  </Dropzone.Idle>

                  <div>
                    <Text size="sm" inline>
                      Drag image here or click to select file.
                    </Text>
                  </div>
                </Group>
              </Dropzone>
            </>
          )}

          <Grid my="lg">
            <Grid.Col span={6}>
              <Box
                p={16}
                style={{
                  borderRadius: 4,
                  border: '1px solid var(--mantine-color-gray-2)',
                }}
              >
                <BioStyle
                  onChange={(value: string) => {
                    setBioCardBlock({
                      ...bioCardBlock,
                      card_style: value,
                    });
                  }}
                  activeStyle={bioCardBlock.card_style}
                />
              </Box>
            </Grid.Col>
            {/* <Grid.Col span={6}>
              <Box
                p={16}
                style={{
                  borderRadius: 4,
                  border: '1px solid var(--mantine-color-gray-2)',
                }}
              >
                <BioAnimation />
              </Box>
            </Grid.Col> */}
          </Grid>
        </Fieldset>

        <Divider my={'md'} />
        <Center>
          <Group>
            <Button onClick={handleAddBlock}>
              {bioCardBlock._id ? 'Update Block' : 'Add Block'}
            </Button>
          </Group>
        </Center>
      </Box>
    </>
  );
};
