import React from 'react';
import { Box, Container, ScrollArea, Space, Text } from '@mantine/core';
import classes from '../../BioLinks.module.css';
import { BioCard, BioLink, BioProfile, BioRSS, BioSocial, BioText, BioVideo } from '@replug/ui';
import { createContrastGradientBackground } from '@replug/utils';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';

export const BioLinkPreviewContainer = ({
  values,
  blocks,
  isListing = false,
}: {
  values: any;
  blocks: any;
  isListing?: boolean;
}) => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);

  const getBlockComponent = (block: any, index: any) => {
    switch (block.type) {
      case 'text':
        return <BioText values={block} theme={values.theme} />;
      case 'social':
        return <BioSocial values={block} theme={values.theme} />;
      case 'rss':
        return <BioRSS values={block} theme={values.theme} />;
      case 'video':
        return <BioVideo values={block} theme={values.theme} />;
      case 'card':
        return <BioCard values={block} theme={values.theme} />;
      case 'links':
        return <BioLink values={block} theme={values.theme} />;
      default:
        return <Text>...</Text>;
    }
  };

  return (
    <Box className={isListing ? classes.listPreviewContainer : classes.mobileContainer}  
    style={{
      borderRadius: '40px',
      overflow: 'hidden',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
      maxWidth: '312px',
      border: '8px solid #222',
    }}>
      <Box
        className={isListing ? classes.listPreviewContainerInner : classes.mobileContainerInner}
        style={{
          background: values.theme?.background_style === 'gradient'
            ? createContrastGradientBackground(values.theme?.background_color)
            : values.theme?.background_color || '#fff',
          borderRadius: '40px',
          boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {/* iPhone Notch */}
        <Box
          style={{
            position: 'absolute',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            width: '50%',
            height: '30px',
            backgroundColor: '#000',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            zIndex: 10,
          }}
        />
        
        <ScrollArea
          scrollbarSize={4}
          h={isListing && defaultWorkspace?.powered_by ? 400 : isListing && !defaultWorkspace?.powered_by ? 440 : !isListing && !defaultWorkspace ? 653 : 623}
          style={{ marginTop: '30px' }} // Add margin to account for the notch
        >
          <Container>
            <BioProfile values={values} />
            {blocks.map((block: any, index: any) => (
              <React.Fragment key={index}>
                {getBlockComponent(block, index)}
                <Space h="xs" />
              </React.Fragment>
            ))}
          </Container>
        </ScrollArea>
        
        {defaultWorkspace?.powered_by && (
          <Box className={isListing ? classes.listPreviewContainerInnerFooter : classes.mobileContainerInnerFooter}>
            {!defaultWorkspace?.agency?.is_whitelabel_enabled && (
              <Text fz={11} color={'gray'}>
                Bio link created using <strong>{defaultWorkspace?.agency?.name}</strong>
              </Text>
            )}
          </Box>
        )}
        
        {/* iPhone Home Indicator */}
        <Box
          style={{
            position: 'absolute',
            bottom: '5px',
            left: '50%',
            transform: 'translateX(-50%)',
            width: '40%',
            height: '5px',
            backgroundColor: '#000',
            borderRadius: '3px',
          }}
        />
      </Box>
    </Box>
  );
};