import { Flex, Title, Text, Image, Box } from '@mantine/core';
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { Carousel } from '@mantine/carousel';
import '@mantine/carousel/styles.css';
import classes from './AuthCarousel.module.css';
import {  isWhitelabelDomain } from '@/utils/CommonUtility';

export function AuthCarousel() {
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  const FEATURES = [
    ...((!isWhitelabelDomain()) ? [{
      title: 'Introducing Replug 2.0: Your Link Management Suite, Reimagined 🚀',
      description: 'Get ready to level up with Replug for a faster, smoother, smarter link management experience.',
      image: 'https://storage.googleapis.com/replug-assets/web_app/d9fe0f39-087c-4c07-ab07-9828c3da69fe.jpg',
    }] : [{
      title: 'Your Link Management Suite, Reimagined 🚀',
      description: 'Get ready to level up with  for a faster, smoother, smarter link management experience.',
      image: 'https://storage.googleapis.com/replug-assets/web_app/d9fe0f39-087c-4c07-ab07-9828c3da69fe.jpg',
    }]),
    {
      title: 'Brand new dashboard UI/UX ',
      description:
        'Enriched design and optimized functionality for an agile and more robust user experience.',
      image: 'https://storage.googleapis.com/replug-assets/web_app/4036761d-1ccf-46f7-9bdf-b8e442e10278.jpg',
    },
    {
      title: 'Introduction of workspaces',
      description:
        ' Streamline and organize your campaigns, teams, and roles access to collaborate efficiently.',
      image: 'https://storage.googleapis.com/replug-assets/web_app/ef65d4c3-e52f-4407-b67f-2e6589962c56.jpg',
    },
    {
      title: 'Revamped bio-links',
      description:
        ' Personalize your bio links with stunning, easy-to-use templates that reflect your style and brand.',
      image: 'https://storage.googleapis.com/replug-assets/web_app/06a7cf6f-d9d3-44e6-8367-a4c4ad127e00.jpg',
    },
    {
      title: 'Dive deeper into analytics with QR scans',
      description:
        " Track QR code engagements and link clicks to gain powerful insights that drive informed decisions for your campaign success!",
      image: 'https://storage.googleapis.com/replug-assets/web_app/494b6d1c-1713-4981-b884-0de058684402.jpg',
    },
    {
      title: 'Upgraded team member functionality',
      description:
        ' Empower your team with enhanced collaboration features. Organize teams, assign roles, and collaborate more effectively than ever.',
      image: 'https://storage.googleapis.com/replug-assets/web_app/7fb0c5dd-c632-4b5f-93fc-238f2a6972f5.jpg',
    },
    // {
    //   title: 'New Feature: Deep Links',
    //   description:
    //     ' Create links to navigate users to the Google Play Store or Apple Store and download the intended app. Play videos and songs directly from an appropriate app to increase app installations.',
    //   image: DeepLinkingImage,
    // },
    // {
    //   title: 'New Feature: Traffic Routing',
    //   description:
    //     'Traffic routing rules empower you to target specific audiences, audience segmentation, and the ability to have multiple URL destinations. Get total control of your links, all intending to boost your conversions.',
    //   image: TrafficRoutingImage,
    // },
    // {
    //   title: 'New Feature: A/B Testing',
    //   description:
    //     'Webpage A/B testing gives you insight on which page performs better based on conversion rates, session times, and bounce rate. Create high converting marketing campaigns by A/B testing your landing pages. ',
    //   image: ABTestingImage,
    // }
  ];


  return (
    <Flex
      style={{
        height: '100%',
        width: '100%',
      }}
      // align="center"
      justify="center"
    >
      <Box w="100%" mt="8%">
        <Carousel
          loop
          height={600}
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
          withIndicators
          withControls={false}
          classNames={{ indicator: classes.indicator }}
        >
          {FEATURES.map((feature, index) => (
            <>
              <Carousel.Slide key={index}>
                <Title ta="center" order={1} mb={16} size="1.3rem">
                  {feature.title}
                </Title>
                <Text mb={32} ta="center" px={40}>
                  {feature.description}
                </Text>
                <Image
                  src={feature.image}
                  width="80%"
                  height={400}
                  fit="contain"
                  mx="auto"
                />
              </Carousel.Slide>
            </>
          ))}
        </Carousel>
      </Box>
    </Flex>
  );
}
